.legend {
  float:right;
  margin-top: 10px;
}
.legend-item {
  display: inline;
  margin-left: 10px;
}
.legend-box {
  display: inline-flex;
  height: 15px;
  width: 15px;
  color: #333333 !important;
  border: 1px solid #333333;
}
.legend-borderless {
  border: none;
  background-color: #fff;
}
.legend-warning {
  background-color: lighten($warning, 25%);
}
.legend-danger {
  background-color: lighten($danger, 25%);
}
.legend-success {
  background-color: lighten($success, 25%);
}
.legend-teal {
  background-color: lighten($teal, 25%);
}
