/*
-----------------------------------------------------
MANAGE ORGANISERS
-----------------------------------------------------
*/
*[data-href] {
  cursor: pointer;
}

#wrapper {
  overflow-x: hidden;
}

.editor-toolbar {
  border-radius: 0 !important;
}

.CodeMirror, .CodeMirror-scroll {
  min-height: 100px !important;
}

.create_organiser, .address-manual {
  padding: 10px;
  border: 1px solid #ddd;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #FAFAFA;
}

.in-form-link {
  display: block;
  padding: 5px;
  margin-bottom: 5px;
  padding-left: 0;
}

.page-title .title .organiser_logo {
  position: absolute;
  height: 45px;
  right: 20px;
  top: 5px;
  bottom: 5px;
}

.page-title .title .organiser_logo img {
  max-height: 45px;
}

/* Dashboard calendar */
#calendar {
  border: 1px solid #ddd;
  background: #fff;
  /*padding: 17px;*/
}

#calendar .fc-button {
  background: transparent;
  border: none;
  color: inherit;
  box-shadow: none;
}

#calendar .fc-event {
  border-color: #fff;
  -webkit-border-radius: 0;
  border-radius: 0;
  padding: 2px;
  transition: none;
}

#calendar .fc-toolbar {
  text-align: center;
  margin-bottom: 0em;
  padding: 7px;
}

#calendar h2 {
  font-size: 15px;
  text-transform: uppercase;
  margin-top: 6px;
}

#calendar .fc-view {
  margin: -1px;
}


/*
-----------------------------------------------------
MANAGE EVENT
-----------------------------------------------------
*/

.nav {
  li {
    &.nav-button {
      a {
        span {
          padding: 10px;
        }
      }
    }
  }
}


/*
-----------------------------------------------------
EVENTS DASHBOARD
-----------------------------------------------------
*/
.event {

  &.panel {
    /*border-top: 4px solid $default;*/
    margin-top: 10px;
  }

  .event-date {
    /*background-color: rgba(0, 0, 0, 0.15);*/
    border: 1px solid $white;
    padding-bottom: 9px;
    padding-top: 7px;
    text-align: center;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    width: 46px;
    position: absolute;
    background-color: #ffffff;
    top: -13px;
    border-color: $primary;
    color: #666;

    .day {
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 22px;
      font-weight: 500;
      margin: -2px auto -6px auto;
    }

    .month {
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 10px;
      font-weight: 500;
      margin: -2px auto 0 auto;
    }
  }

  .event-meta {
    margin: 0;
    padding: 0;
    margin-left: 60px;
    height: 55px;
    margin-top: 10px;
    color: #ffffff;

    li {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      list-style: none;
      font-size: 12px;

      a {
        color: #ffffff;
      }

      &.event-title {

        a {
          font-size: 16px;
        }

      }

      &.event-organiser {

        a {
          font-weight: bold;
        }

      }


    }


  }

  .panel-title {
    height: 60px;
    padding: 10px;

    a {
      margin: 0;
      height: 40px;
      display: table-cell;
      vertical-align: middle;
      text-indent: 50px;

    }

  }
}


/*
-----------------------------------------------------
Event Dashboard
-----------------------------------------------------
*/

.stat-box {
  padding: 20px;
  background-color: $white;
  color: $attendize-base-color;
  text-align: center;
  margin-bottom: 10px;
  border: 1px solid $border-color;


  h3 {
    margin-bottom: 5px;
    margin-top: 0;
    font-weight: 200;
  }

  span {
    text-transform: uppercase;
    font-weight: lighter;
    color: lighten($dark, 10%);
  }
}


/* Translatable flags */
.translatable {
  float: right;
}

.btn-translatable {
  margin-top: 5px;
  padding: 0.3rem 0.7rem;
  line-height: 1.5em;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 5px
}

.note-frame.card {
  margin-bottom: 0 !important;
}

/*
-----------------------------------------------------
GLOBAL
-----------------------------------------------------
*/

.top_of_page_alert {
  border: none;
  margin: 0;
  text-align: center;
  border-bottom: 5px solid;
}

/*Veritcal align text helper*/
.v-align-text {
  text-align: center;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -wekbit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (max-width: $screen-md) {
  .page-header > [class*=" col-"], .page-header > [class^="col-"] {
    margin-bottom: 10px;
  }
}

/*Make the button toolbars responsive.*/
@media (max-width: $screen-xs) {
  .btn-group-responsive {
    margin-bottom: -10px;
    float: none !important;
    display: block !important;

    .btn {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 10px;
    }

    .pull-left, .pull-right {
      float: none !important;
    }
  }
}

.dropdown-menu {
  z-index: 1002 !important;
}

/* Little red astrix to indicate required form fields */
label.required::after {
  content: '*';
  color: red;
  padding-left: 3px;
  font-size: 9px;
}

/* Make readonly date inputs look nice */
.hasDatepicker {
  cursor: pointer !important;
}

/* */
.more-options {
  display: none;
}

/* Google autocomplete */
.pac-container {
  z-index: 9999;
}

.dtpicker-overlay {
  z-index: 9999;
}

.dtpicker-close {
  display: none;
}

.dtpicker-header .dtpicker-title {
  color: #AFAFAF;
  text-align: center;
  font-size: 18px;
  font-weight: normal;
}

.dtpicker-header .dtpicker-value {
  padding: .8em .2em .2em 2em;
  color: $primary;
  text-align: center;
  font-size: 1.4em;
}

/* Datetime picket */
.dtpicker-buttonCont .dtpicker-buttonClear {
  background: $danger;
  border-radius: $border-radius;
}

.dtpicker-buttonCont .dtpicker-buttonSet {
  background: $success;
  border-radius: $border-radius;
}

dtpicker-buttonSet
.dtpicker-content {
  border-radius: $border-radius;
}

.order_options .event_count {
  font-weight: bold;
  color: #777;
  //line-height: 30px;
}

.well {
  background-color: #f9f9f9;
  box-shadow: none;
}


.input-group-btn select {
  width: 115px !important;
  border-left: 0;
  font-size: 12px;
}

/* box widget */
.box {
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  border-top: 3px solid #d2d6de;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.box.box-primary {
  border-top-color: #3c8dbc;
}

.box.box-info {
  border-top-color: #00c0ef;
}

.box.box-danger {
  border-top-color: #dd4b39;
}

.box.box-warning {
  border-top-color: #f39c12;
}

.box.box-success {
  border-top-color: #00a65a;
}

.box.box-default {
  border-top-color: #d2d6de;
}

.box.collapsed-box .box-body,
.box.collapsed-box .box-footer {
  display: none;
}

.box .nav-stacked > li {
  border-bottom: 1px solid #f4f4f4;
  margin: 0;
}

.box .nav-stacked > li:last-of-type {
  border-bottom: none;
}

.box.height-control .box-body {
  max-height: 300px;
  overflow: auto;
}

.box .border-right {
  border-right: 1px solid #f4f4f4;
}

.box .border-left {
  border-left: 1px solid #f4f4f4;
}

.box.box-solid {
  border-top: 0;
}

.box.box-solid.box-default {
  border: 1px solid #d2d6de;
}

.box.box-solid.box-default > .box-header {
  color: #444;
  background: #d2d6de;
  background-color: #d2d6de;
}

.box.box-solid.box-primary {
  border: 1px solid #3c8dbc;
}

.box.box-solid.box-primary > .box-header {
  color: #fff;
  background: #3c8dbc;
  background-color: #3c8dbc;
}

.box.box-solid.box-info {
  border: 1px solid #00c0ef;
}

.box.box-solid.box-info > .box-header {
  color: #fff;
  background: #00c0ef;
  background-color: #00c0ef;
}

.box.box-solid.box-danger {
  border: 1px solid #dd4b39;
}

.box.box-solid.box-danger > .box-header {
  color: #fff;
  background: #dd4b39;
  background-color: #dd4b39;
}

.box.box-solid.box-warning {
  border: 1px solid #f39c12;
}

.box.box-solid.box-warning > .box-header {
  color: #fff;
  background: #f39c12;
  background-color: #f39c12;
}

.box.box-solid.box-success {
  border: 1px solid #00a65a;
}

.box.box-solid.box-success > .box-header {
  color: #fff;
  background: #00a65a;
  background-color: #00a65a;
}

.box.box-solid[class*='bg'] > .box-header {
  color: #fff;
}

.box .box-group > .box {
  margin-bottom: 5px;
}

.box .knob-label {
  text-align: center;
  color: #333;
  font-weight: 100;
  font-size: 12px;
  margin-bottom: 0.3em;
}

.box > .overlay,
.overlay-wrapper > .overlay,
.box > .loading-img,
.overlay-wrapper > .loading-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.box .overlay,
.overlay-wrapper .overlay {
  z-index: 50;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 3px;
}

.box .overlay > .fa,
.overlay-wrapper .overlay > .fa {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  color: #000;
  font-size: 30px;
}

.box .overlay.dark,
.overlay-wrapper .overlay.dark {
  background: rgba(0, 0, 0, 0.5);
}

.box-header:before,
.box-body:before,
.box-footer:before,
.box-header:after,
.box-body:after,
.box-footer:after {
  content: " ";
  display: table;
}

.box-header:after,
.box-body:after,
.box-footer:after {
  clear: both;
}

.box-header {
  color: #444;
  display: block;
  padding: 10px;
  position: relative;
}

.box-header.with-border {
  border-bottom: 1px solid #f4f4f4;
}

.collapsed-box .box-header.with-border {
  border-bottom: none;
}

.box-header > .fa,
.box-header > .glyphicon,
.box-header > .ion,
.box-header .box-title {
  display: inline-block;
  font-size: 18px;
  margin: 0;
  line-height: 1;
}

.box-header > .fa,
.box-header > .glyphicon,
.box-header > .ion {
  margin-right: 5px;
}

.box-header > .box-tools {
  position: absolute;
  right: 10px;
  top: 5px;
}

.box-header > .box-tools [data-bs-toggle="tooltip"] {
  position: relative;
}

.box-header > .box-tools.pull-right .dropdown-menu {
  right: 0;
  left: auto;
}

.box-header > .box-tools .dropdown-menu > li > a {
  color: #444 !important;
}

.dropdown-menu > li > a.active:hover {
  color: #fff !important;
}

.dropdown-menu > li > a.active, .dropdown-item:active {
  background-color: #444 !important;
  color: #fff;
}

.dropdown-menu > li > a:hover, .dropdown-item:hover {
  background-color: #ddd !important;
  color: #444 !important;
}

.box-body {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 10px;
}

.no-header .box-body {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.box-body > .table {
  margin-bottom: 0;
}

.box-body .fc {
  margin-top: 5px;
}

.box-body .full-width-chart {
  margin: -19px;
}

.box-body.no-padding .full-width-chart {
  margin: -9px;
}

.box-body .box-pane {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 3px;
}

.box-body .box-pane-right {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 0;
}

.box-footer {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top: 1px solid #f4f4f4;
  padding: 10px;
  background-color: #fff;
}

.chart-legend {
  margin: 10px 0;
}

@media (max-width: 991px) {
  .chart-legend > li {
    float: left;
    margin-right: 10px;
  }
}

.box-comments {
  background: #f7f7f7;
}

.box-comments .box-comment {
  padding: 8px 0;
  border-bottom: 1px solid #eee;
}

.box-comments .box-comment:before,
.box-comments .box-comment:after {
  content: " ";
  display: table;
}

.box-comments .box-comment:after {
  clear: both;
}

.box-comments .box-comment:last-of-type {
  border-bottom: 0;
}

.box-comments .box-comment:first-of-type {
  padding-top: 0;
}

.box-comments .box-comment img {
  float: left;
}

.box-comments .comment-text {
  margin-left: 40px;
  color: #555;
}

.box-comments .username {
  color: #444;
  display: block;
  font-weight: 600;
}

.box-comments .text-muted {
  font-weight: 400;
  font-size: 12px;
}

/* Custom file input */
.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  background: red;
  cursor: inherit;
  display: block;
}

input[readonly] {
  background-color: white !important;
  cursor: text !important;
}

html.working {
  cursor: progress;
}

.order_options {
  padding: 10px 0;
}

/* Color picker*/
.minicolors-theme-default.minicolors {
  width: auto;
  display: block;
}

.minicolors-theme-default .minicolors-input {
  padding-left: 35px;
  height: auto;
  width: 100%;
  display: block;
}

.minicolors-theme-default .minicolors-swatch {
  top: 8px;
  left: 6px;
  width: 18px;
  height: 18px;
}

.btn-default .caret {
  border-top-color: #fff;
}

.input-group .form-control:first-child, .input-group-addon:first-child, .input-group-btn:first-child > .btn, .input-group-btn:first-child > .dropdown-toggle, .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border-right: none;
}

.modal-backdrop {
  //background: url(/images/background.jpg) repeat;
  background-color: #181010;
}

.ticket .sortHandle {
  width: 20px;
  height: 20px;
  color: #dfdfdf;
  font-size: 20px;
  position: absolute;
  bottom: 20px;
  left: 15px;
  cursor: move;
  z-index: 10;
}

// Fix the editor fullscreen mode
.editor-toolbar.fullscreen, .CodeMirror-fullscreen {
  z-index: 10000 !important;
}

.notifyjs-corner {
  z-index: 2147483647 !important;
}

#searchclear {
  position: absolute;
  left: 5px;
  top: 0;
  bottom: 0;
  height: 14px;
  margin: auto;
  font-size: 14px;
  cursor: pointer;
  color: #ccc;
  z-index: 3;
}

.searchbox {
  padding-left: 20px;
}

table#table-results.display tbody tr:hover td {
  background-color: #ffd25b !important;
  color: #000 !important;
}

// OVT - Overtime
table#table-results.display tr.ovt:nth-child(odd) td {
  background-color: #B5C8FA;
}

table#table-results.display tr.ovt:nth-child(even) td {
  background-color: #91ADF8;
}

// DNF - Do not Finished
table#table-results.display tr.dnf:nth-child(odd) td {
  background-color: #F07700;
  color: #E9E9E9
}

table#table-results.display tr.dnf:nth-child(even) td {
  background-color: #C46100;
  color: #E9E9E9
}

// DSQ - Disqualified
table#table-results.display tr.dsq:nth-child(odd) td{
  background-color: #CC2400;
  color: #E9E9E9;
}

table#table-results.display tr.dsq:nth-child(even) td{
  background-color: #F02A00;
  color: #E9E9E9;
}

// DNS - Do Not Started
table#table-results.display tr.dns:nth-child(odd) td {
  background-color: #AFAFAF;
}

table#table-results.display tr.dns:nth-child(even) td {
  background-color: #DCDCDC;
}

// FIN - Finished
table#table-results.display tr.fin:nth-child(odd) td {
  background-color: #d7ffc8;
}

table#table-results.display tr.fin:nth-child(even) td {
  background-color: #ecffe5;
}

#qrcode {
  min-width: 210px;
  min-height: 210px;
  padding: 15px
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}

.dataTables_paginate {
  float: left;
  margin: 0;
}

.tfoot-filter {
  display: table-header-group !important;
}
.tfoot-filter > tr >th.dtr-hidden {
  display: table-cell !important;
}

.tfoot-filter th {
  background-color: #fff !important;
}

// NOTIFICATIONS
.notification-ui a:after {
  display: none;
}

.notification-ui_icon {
  position: relative;
}

.notification-ui_icon .unread-notification {
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 7px;
  position: absolute;
  top: 2px;
  left: 12px;
}

@media (min-width: 900px) {
  .notification-ui_icon .unread-notification {
    left: 10px;
  }
}

.notification-ui_dd {
  padding: 0;
  border-radius: 10px;
  -webkit-box-shadow: 0 5px 20px -3px rgba(0, 0, 0, 0.16);
  box-shadow: 0 5px 20px -3px rgba(0, 0, 0, 0.16);
  border: 0;
  max-width: 400px;
}

@media (min-width: 900px) {
  .notification-ui_dd {
    min-width: 400px;
    position: absolute;
    left: -192px;
    top: 70px;
  }
}

.notification-ui_dd:after {
  content: "";
  position: absolute;
  top: -30px;
  left: calc(50% - 7px);
  border-top: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #fff;
  border-left: 15px solid transparent;
}

.notification-ui_dd .notification-ui_dd-header {
  border-bottom: 1px solid #ddd;
  padding: 15px;
}

.notification-ui_dd .notification-ui_dd-header h3 {
  margin-bottom: 0;
}

.notification-ui_dd .notification-ui_dd-content {
  max-height: 500px;
  overflow: auto;
}

.notification-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 20px 0;
  margin: 0 25px;
  border-bottom: 1px solid #ddd;
}

.notification-list--unread {
  position: relative;
}

.notification-list--unread:before {
  content: "";
  position: absolute;
  top: 0;
  left: -25px;
  height: calc(100% + 1px);
  border-left: 2px solid #dc4a4a;
}

.notification-list .notification-list_img img {
  height: 48px;
  width: 48px;
  border-radius: 50px;
  margin-right: 20px;
}

.notification-list .notification-list_detail p {
  margin-bottom: 5px;
  line-height: 1.2;
}

.notification-list .notification-list_feature-img img {
  height: 48px;
  width: 48px;
  border-radius: 5px;
  margin-left: 20px;
}

.messages.new > td {
  font-weight: bold;
}

.messages > td.important {
  font-weight: bold;
  color: $danger;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.pointer {
  cursor: pointer;
}

.show-list-muc-modal {
  display: none !important;
}

.flatpickr-confirm {
  background: rgb(255 0 0 / 80%);
  color: #fff;
}

.invalid-feedback {
  font-size: small !important;
}

table.dataTable > tbody > tr.child ul.dtr-details {
  display: inline !important;
}
