/* ----------------------------
 *
 * Page Header
 *
 * --------------------------- */

.page-title {
    padding: 10px 15px 10px 15px;
    margin: -15px;
    background-color: #F7F7F7;
    border-bottom: 1px solid $border-color;
    border-top: 1px solid $border-color;
    margin-bottom: 15px;
    position: relative;
}

.page-title .title{
    color: $attendize-base-color;
    font-size: 16px;
    line-height: 34px;
    font-weight: 400;
    margin: 0;
}

.page-header {
    padding-bottom: 15px;
    margin: 0px 0 15px;
    border-bottom: 1px solid $border-color;
    overflow: visible;
}
.page-header.no-border {
    border-bottom-width: 0px;
}
.page-header .title {
    color: lighten($dark, 20%);
    font-size: 20px;
    line-height: 34px;
    font-weight: 400;

    margin-left: -15px;
    margin-right: -15px;
    border-bottom: 1px solid $border-color;
    padding: 10px 15px;
    margin-bottom: 15px;
    margin-top: -15px;
}
.page-header > [class*=" col-"],
.page-header > [class^="col-"] {
    padding-left: 0px;
    padding-right: 0px;
}
.page-header > .page-header-section + .page-header-section {
    margin-top: 10px;
}

/* toolbar */
.page-header .toolbar {
    text-align: left;
    margin-bottom: 5px;
}
.page-header .toolbar [class*=" col-"],
.page-header .toolbar [class^="col-"] {
    padding-left: 0px;
    padding-right: 0px;
}
.page-header .toolbar .toolbar-label {
    display: block;
    text-align: left;
}

/* Block */
.page-header.page-header-block {
    width: auto;
    padding: 14px 15px 16px 15px;
    background-color: $white;
    border-bottom-color: darken($maincontent-base-color, 6%);

}
.container-fluid .page-header.page-header-block {
    margin: -15px -15px 15px -15px;
}

.page-header.page-header-block > [class*=" col-"],
.page-header.page-header-block > [class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
}

@media (min-width: $screen-sm-min) {
    .page-header > .page-header-section {
        display: table-cell;
        width: 1%;
        vertical-align: middle;
        margin-top: 0px;
    }
    /* toolbar */
    .page-header .toolbar {
        text-align: right;
        margin-bottom: 0px;
    }
    .page-header .toolbar .toolbar-label {
        display: inline-block;
        vertical-align: middle;
        line-height: 34px;
    }
}

/* Horizontal rules */
hr {
    border-color: $border-color;
}