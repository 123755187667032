/* Push left */
#main {
    position: relative;
    transition: transform 0.2s ease;
}
#header.navbar {
    ~ #main {
        padding-top: $header-height;
    }
}
#header.navbar{
    &.navbar-fixed-top {
        ~ #main {
            padding-top: $header-height + $header-height;
        }
    }
}

#main {
    > .container-fluid,
    > .container {
        padding: 15px;
    }
}

