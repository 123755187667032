/* Sidebar Main  */
.touch {
    .sidebar {
        // sidebar content
        // -----------------------------------
        .content {
            overflow-y: scroll;
            -ms-overflow-style: -ms-autohiding-scrollbar;
            -webkit-overflow-scrolling: touch;
        }
    }
}
.sidebar-wrapper {
    color: lighten($sidebar-base-color, 30%);
    background-color: $sidebar-base-color;
    transition: transform 0.2s ease;

    .content {
        > .wrapper {
            padding-left: 25px;
            padding-right: 25px;
        }

        hr {
            border-color: lighten($sidebar-base-color, 2%);
        }

        > .heading {
            font-size: 13px;
            font-weight: 400;
            text-transform: uppercase;
            color: $white;
            margin: 0px;
            padding: 25px 25px 15px 25px;
        }

        a {
            display: table;
            position: relative;
            table-layout: fixed;
            width: 100%;
            font-size: 12px;
            text-decoration: none;
            color: lighten($sidebar-base-color, 50%);
            background-color: $sidebar-base-color;
            outline: 0;
            padding: 6px 25px 6px 12px;

            // State - hover
            // -----------------------------------
            &:hover {
                color: lighten($sidebar-base-color, 60%);

                > .figure > [class^="ico-"],
                > .figure > [class*=" ico-"] {
                    border-color: lighten($sidebar-base-color, 60%);
                }
            }

            // Menu link - figure
            // -----------------------------------
            > .figure {
                display: table-cell;
                table-layout: fixed;
                vertical-align: middle;
                width: 40px;
                padding-left: 12px;
                font-size: 15px;

                > .hasnotification {
                    position: absolute;
                    left: 16px;
                }
            }

            // Menu link - text
            // -----------------------------------
            > .text {
                display: table-cell;
                table-layout: fixed;
                vertical-align: middle;
                width: 100%;
                line-height: 30px;
                font-weight: 200;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-transform: uppercase;
            }
        }

        .active {
            background-color: rgba(255,255,255,.1) !important;
            border-color:  $sidebar-base-color !important;
            color: #fff;
        }
        .list-group-item:hover {
            background-color: rgba(255,255,255,.1) !important;
        }
        .list-group-item {
            border: 0;
        }
    }
}