/* ----------------------------
 *
 * Custom Scrollbar
 *
 * --------------------------- */
.scrollrail {
    opacity: 1 !important;
    background-color: rgba(0, 0, 0, 0.05) !important;
    border: 0px !important;
    border-radius: 0px !important;
}
.scrollbar {
    opacity: 1 !important;
    background-color: rgba(0, 0, 0, 0.3) !important;
    border: 0px !important;
    border-radius: $border-radius - 1 !important;
}