/* Header Main */
#header {
    position: relative;
    z-index: 1030;
    width: 100%;
    background-color: $white !important;
    height: $header-height;
    border-width: 0px;
    border-radius: 0px;
    margin: 0px;
    transition :transform 0.2s ease;

    .dropdown-toggle, .nav-link {
        font-weight: 600 !important;
        color: $attendize-base-color !important;
    }
}