@media (min-width: $screen-md-min) {
    /* Sidebar Transition  */

    /* Sidebar Main  */
    .sidebar {
        // sidebar left
        // -----------------------------------
        &.sidebar-left {
            &.sidebar-menu {
                position: absolute;
                left: 0px;
                padding-top: 10px;
                bottom: auto;
                min-height: 100%;

                // sidebar viewport
                // -----------------------------------
                > .viewport {
                    overflow: visible !important;

                    > .content {
                        overflow: visible !important;
                    }
                    > .scrollbar,
                    > .scrollrail {
                        display: none !important;
                    }
                }
            }
        }
    }

    /* Sidebar minimized  */
    .sidebar-minimized {
        .sidebar {
            // sidebar left
            // -----------------------------------
            &.sidebar-left {
                &.sidebar-menu {
                    position: absolute;
                    left: 0;
                    padding-top: 10px;
                    bottom: auto;
                    width: $sidebar-collapse-width;
                    min-height: 100%;

                    // sidebar left - viewport
                    // -----------------------------------
                    > .viewport {
                        overflow: visible !important;

                        > .content {
                            overflow: visible !important;
                        }
                        > .scrollbar,
                        > .scrollrail {
                            display: none !important;
                        }
                    }
                }
            }

            // sidebar content
            // -----------------------------------
            .content {
                > .wrapper {
                    display: none;
                }
                > .heading {
                    display: none;
                }
            }

            // sidebar menu
            // -----------------------------------
            .topmenu {
                // Targetting 1st lvl menu
                // -----------------------------------
                > li {
                    &:after {
                        left: 0;
                    }

                    &.open {
                        > .submenu.collapsing,
                        > .submenu.collapse.in {
                            display: none;
                        }
                    }

                    &.hover,
                    &.open.hover,
                    &:hover,
                    &.open:hover {
                        > .submenu.collapse,
                        > .submenu.collapsing,
                        > .submenu.collapse.in {
                            display: block;
                            height: auto !important;
                        }

                        // Arrow
                        // -----------------------------------
                        &.arrow:before {
                            position: absolute;
                            z-index: 1;
                            top: 50%;
                            right: 0;
                            margin-top: -8px;
                            content: "";

                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 8px 8px 8px 0;
                            border-color: transparent darken($sidebar-base-color, 4%) transparent transparent;

                            -webkit-box-shadow:inset 1px 0 4px 0 darken($sidebar-base-color, 5%);
                            box-shadow:inset 1px 0 4px 0 darken($sidebar-base-color, 5%);
                        }
                    }

                    &.active {
                        > a {
                            > .number {
                                .label,
                                .badge {
                                    -webkit-box-shadow: 0 0 0 2px lighten($sidebar-base-color, 2%);
                                    box-shadow: 0 0 0 2px lighten($sidebar-base-color, 2%);
                                }
                            }
                        }
                    }

                    // Menu link
                    // -----------------------------------
                    > a {
                        padding: 0 15px;
                        line-height: $sidebar-collapse-width;

                        > .text {
                            display: none;
                        }
                        > .number {
                            display: block;
                            position: absolute;
                            top: 12px;
                            right: 10px;
                            width: auto;

                            .label,
                            .badge {
                                -webkit-box-shadow: 0 0 0 2px $sidebar-base-color;
                                box-shadow: 0 0 0 2px $sidebar-base-color;
                            }
                        }
                        > .arrow {
                            display: none;
                        }
                        > .figure {
                            display: block;
                            width: auto;
                            font-size: 18px;
                            padding: 0;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}