.wizard,
.wizard > .nav-tabs,
.wizard > .nav-tabs .nav-item {
  position: relative;
}
.wizard > .nav-tabs:after {
  content: "";
  width: 80%;
  border-bottom: solid 2px #ccc;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 38%;
  z-index: -1;
}
.wizard > .nav-tabs .nav-item .nav-link {
  width: 70px;
  height: 70px;
  margin-bottom: 6%;
  background: white;
  border: 2px solid #ccc;
  color: #ccc;
  z-index: 10;
  pointer-events: none;
}
.wizard > .nav-tabs .nav-item .nav-link.active {
  background: #fff;
  border: 2px solid #0dcaf0;
  color: #0dcaf0;
}
.wizard >  .nav-tabs .nav-item .nav-link:after {
  content: " ";
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  opacity: 0;
  margin: 0 auto;
  bottom: 0px;
  border: 5px solid transparent;
  border-bottom-color: #0dcaf0;
  transition: 0.1s ease-in-out;
}

.wizard > .nav-tabs .nav-item .nav-link.active:after {
  content: " ";
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  opacity: 1;
  margin: 0 auto;
  bottom: 0px;
  border: 10px solid transparent;
  border-bottom-color: #0dcaf0;
}
.wizard > .nav-tabs .nav-item .nav-link {
  font-size: 25px;
}

.wizard > .bg-white {
  --bs-bg-opacity: 0 !important;
}
