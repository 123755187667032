@import "base";


/*BEGIN LAYOUT*/
@import "scss/layout/global";

@import "scss/layout/global";
@import "scss/layout/header";
@import "scss/layout/header-mq";
@import "scss/layout/sidebar";
@import "scss/layout/sidebar-mq";
@import "scss/layout/maincontent";
@import "scss/layout/maincontent-mq";
/*END LAYOUT*/


/*BEGIN UI*/
@import "scss/ui/helper";
@import "scss/ui/form";
@import "scss/ui/button";
@import "scss/ui/breadcrumb";
@import "scss/ui/dropdown";
@import "scss/ui/labelbadge";
@import "scss/ui/typography";
@import "scss/ui/alert";
@import "scss/ui/nav";
@import "scss/ui/table";
@import "scss/ui/cart";
@import "scss/ui/pageheader";
@import "scss/ui/totop";
@import "scss/ui/scrollbar";
@import "scss/ui/modal";
@import "scss/ui/legend";
@import "scss/ui/wiard";

/*END UI*/

///* Plugins etc */
@import "~rrssb/scss/rrssb";
@import "~chart.js/dist/Chart.css";
@import "~humane-js/themes/flatty.css";
@import "~highcharts/css/highcharts";
//@import "~datetimepicker/dist/DateTimePicker.css";
@import "~bootstrap-touchspin/dist/jquery.bootstrap-touchspin.css";
@import "~jquery-minicolors/jquery.minicolors.css";
@import "~datatables.net-bs5/css/dataTables.bootstrap5.css";
@import "~jquery-datatables-checkboxes/css/dataTables.checkboxes.css";
@import "~datatables.net-responsive-bs5/css/responsive.bootstrap5.css";
@import "~datatables.net-fixedheader-bs5/css/fixedHeader.bootstrap5.css";
@import "~summernote/dist/summernote-lite.css";
@import "~fullcalendar/main.css";
@import "~flatpickr/dist/themes/material_blue.css";
@import "~flatpickr/dist/plugins/confirmDate/confirmDate.css";
@import "../../../public/vendor/cookie-consent/css/cookie-consent.css";

@import "custom";
